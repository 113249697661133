import Container from "~/components/layout/Container";
import type { HizmetListesi } from "../hizmetlerimiz+/_index";


export default function ServicesComponent({ services }: { services: typeof HizmetListesi }) {
  return (
    <div className="py-20">
      <Container className="flex-wrap">
        <h2 className="text-3xl w-full justify-center font-black font-source-sans-pro text-center text-[32px] mb-5">Hizmetlerimiz</h2>
        <p className="flex w-full justify-center text-center font-extralight font-source-sans-pro not-italic mb-10 text-lg md:text-[24px] font-raleway text-[#444444]">
          Al-De Mühendislik olarak, ısıtma, soğutma ve su ısıtma sistemleri ile ilgili hizmetleri sunmaktadır.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          {services.map((service) => (
            <a key={service.title} href={`/hizmetlerimiz/${service.id}`} className="relative group mb-8">
              <img src={service.image} alt={service.title} className="group-hover:shadow-lg w-full md:h-[400px] mix-blend-multiply object-cover" />
              <div className="p-2 bottom-0 left-0 w-full">
                <h3 className="md:text-base text-center font-raleway !text-lg text-[#16437c] !font-bold">{service.title}</h3>
                <p className="mt-2 text-base md:text-[14px] font-ibm-plex h-auto"
                  dangerouslySetInnerHTML={{ __html: service?.description?.replace(/<[^>]*>?/g, '')?.length > 180 ? `${service?.description?.replace(/<[^>]*>?/g, '')?.slice(0, 180)}...` : service?.description?.replace(/<[^>]*>?/g, '') }}
                />
              </div>
            </a>
          ))}
        </div>
      </Container>
    </div>
  );
};